export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burma",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

export const nationalities = [
  "Afghan",
  "Åland Island",
  "Albanian",
  "Algerian",
  "American Samoan",
  "Andorran",
  "Angolan",
  "Anguillan",
  "Antarctic",
  "Antiguan or Barbudan",
  "Argentine",
  "Armenian",
  "Aruban",
  "Australian",
  "Austrian",
  "Azerbaijani, Azeri",
  "Bahamian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Belarusian",
  "Belgian",
  "Belizean",
  "Beninese, Beninois",
  "Bermudian, Bermudan",
  "Bhutanese",
  "Bolivian",
  "Bonaire",
  "Bosnian or Herzegovinian",
  "Motswana, Botswanan",
  "Bouvet Island",
  "Brazilian",
  "BIOT",
  "Bruneian",
  "Bulgarian",
  "Burkinabé",
  "Burundian",
  "Cabo Verdean",
  "Cambodian",
  "Cameroonian",
  "Canadian",
  "Caymanian",
  "Central African",
  "Chadian",
  "Chilean",
  "Chinese",
  "Christmas Island",
  "Cocos Island",
  "Colombian",
  "Comoran, Comorian",
  "Congolese",
  "Cook Island",
  "Costa Rican",
  "Ivorian",
  "Croatian",
  "Cuban",
  "Curaçaoan",
  "Cypriot",
  "Czech",
  "Danish",
  "Djiboutian",
  "Dominican",
  "Ecuadorian",
  "Egyptian",
  "Salvadoran",
  "Equatorial Guinean, Equatoguinean",
  "Eritrean",
  "Estonian",
  "Ethiopian",
  "Falkland Island",
  "Faroese",
  "Fijian",
  "Finnish",
  "French",
  "French Guianese",
  "French Polynesian",
  "French Southern Territories",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Ghanaian",
  "Gibraltar",
  "Greek, Hellenic",
  "Greenlandic",
  "Grenadian",
  "Guadeloupe",
  "Guamanian, Guambat",
  "Guatemalan",
  "Channel Island",
  "Guinean",
  "Bissau-Guinean",
  "Guyanese",
  "Haitian",
  "Heard Island or McDonald Islands",
  "Vatican",
  "Honduran",
  "Hong Kong, Hong Kongese",
  "Hungarian, Magyar",
  "Icelandic",
  "Indian",
  "Indonesian",
  "Iranian, Persian",
  "Iraqi",
  "Irish",
  "Manx",
  "Israeli",
  "Italian",
  "Jamaican",
  "Japanese",
  "Channel Island",
  "Jordanian",
  "Kazakhstani, Kazakh",
  "Kenyan",
  "I-Kiribati",
  "North Korean",
  "South Korean",
  "Kuwaiti",
  "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz",
  "Lao, Laotian",
  "Latvian",
  "Lebanese",
  "Basotho",
  "Liberian",
  "Libyan",
  "Liechtenstein",
  "Lithuanian",
  "Luxembourg, Luxembourgish",
  "Macanese, Chinese",
  "Macedonian",
  "Malagasy",
  "Malawian",
  "Malaysian",
  "Maldivian",
  "Malian, Malinese",
  "Maltese",
  "Marshallese",
  "Martiniquais, Martinican",
  "Mauritanian",
  "Mauritian",
  "Mahoran",
  "Mexican",
  "Micronesian",
  "Moldovan",
  "Monégasque, Monacan",
  "Mongolian",
  "Montenegrin",
  "Montserratian",
  "Moroccan",
  "Mozambican",
  "Burmese",
  "Namibian",
  "Nauruan",
  "Nepali, Nepalese",
  "Dutch, Netherlandic",
  "New Caledonian",
  "New Zealand, NZ",
  "Nicaraguan",
  "Nigerien",
  "Nigerian",
  "Niuean",
  "Norfolk Island",
  "Northern Marianan",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Palauan",
  "Palestinian",
  "Panamanian",
  "Papua New Guinean, Papuan",
  "Paraguayan",
  "Peruvian",
  "Philippine, Filipino",
  "Pitcairn Island",
  "Polish",
  "Portuguese",
  "Puerto Rican",
  "Qatari",
  "Réunionese, Réunionnais",
  "Romanian",
  "Russian",
  "Rwandan",
  "Barthélemois",
  "Saint Helenian",
  "Kittitian or Nevisian",
  "Saint Lucian",
  "Saint-Martinoise",
  "Saint-Pierrais or Miquelonnais",
  "Saint Vincentian, Vincentian",
  "Samoan",
  "Sammarinese",
  "São Toméan",
  "Saudi, Saudi Arabian",
  "Senegalese",
  "Serbian",
  "Seychellois",
  "Sierra Leonean",
  "Singaporean",
  "Sint Maarten",
  "Slovak",
  "Slovenian, Slovene",
  "Solomon Island",
  "Somali, Somalian",
  "South African",
  "South Georgia or South Sandwich Islands",
  "South Sudanese",
  "Spanish",
  "Sri Lankan",
  "Sudanese",
  "Surinamese",
  "Svalbard",
  "Swazi",
  "Swedish",
  "Swiss",
  "Syrian",
  "Chinese, Taiwanese",
  "Tajikistani",
  "Tanzanian",
  "Thai",
  "Timorese",
  "Togolese",
  "Tokelauan",
  "Tongan",
  "Trinidadian or Tobagonian",
  "Tunisian",
  "Turkish",
  "Turkmen",
  "Turks and Caicos Island",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Emirati, Emirian, Emiri",
  "British, UK",
  "American",
  "Uruguayan",
  "Uzbekistani, Uzbek",
  "Ni-Vanuatu, Vanuatuan",
  "Venezuelan",
  "Vietnamese",
  "British Virgin Island",
  "U.S. Virgin Island",
  "Wallis and Futuna, Wallisian or Futunan",
  "Sahrawi, Sahrawian, Sahraouian",
  "Yemeni",
  "Zambian",
  "Zimbabwean",
];

export const countriesWithDetails = [
  { country: "Afghanistan", nationality: "Afghan", country_code: "AF" },
  { country: "Albania", nationality: "Albanian", country_code: "AL" },
  { country: "Algeria", nationality: "Algerian", country_code: "DZ" },
  { country: "Andorra", nationality: "Andorran", country_code: "AD" },
  { country: "Angola", nationality: "Angolan", country_code: "AO" },
  {
    country: "Antigua and Barbuda",
    nationality: "Antiguan or Barbudan",
    country_code: "AG",
  },
  { country: "Argentina", nationality: "Argentine", country_code: "AR" },
  { country: "Armenia", nationality: "Armenian", country_code: "AM" },
  { country: "Australia", nationality: "Australian", country_code: "AU" },
  { country: "Austria", nationality: "Austrian", country_code: "AT" },
  { country: "Azerbaijan", nationality: "Azerbaijani", country_code: "AZ" },
  { country: "Bahamas", nationality: "Bahamian", country_code: "BS" },
  { country: "Bahrain", nationality: "Bahraini", country_code: "BH" },
  { country: "Bangladesh", nationality: "Bangladeshi", country_code: "BD" },
  { country: "Barbados", nationality: "Barbadian", country_code: "BB" },
  { country: "Belarus", nationality: "Belarusian", country_code: "BY" },
  { country: "Belgium", nationality: "Belgian", country_code: "BE" },
  { country: "Belize", nationality: "Belizean", country_code: "BZ" },
  { country: "Benin", nationality: "Beninese", country_code: "BJ" },
  { country: "Bhutan", nationality: "Bhutanese", country_code: "BT" },
  { country: "Bolivia", nationality: "Bolivian", country_code: "BO" },
  {
    country: "Bosnia and Herzegovina",
    nationality: "Bosnian or Herzegovinian",
    country_code: "BA",
  },
  { country: "Botswana", nationality: "Motswana", country_code: "BW" },
  { country: "Brazil", nationality: "Brazilian", country_code: "BR" },
  { country: "Brunei", nationality: "Bruneian", country_code: "BN" },
  { country: "Bulgaria", nationality: "Bulgarian", country_code: "BG" },
  { country: "Burkina Faso", nationality: "Burkinabe", country_code: "BF" },
  { country: "Burundi", nationality: "Burundian", country_code: "BI" },
  { country: "Cambodia", nationality: "Cambodian", country_code: "KH" },
  { country: "Cameroon", nationality: "Cameroonian", country_code: "CM" },
  { country: "Canada", nationality: "Canadian", country_code: "CA" },
  { country: "Cape Verde", nationality: "Cabo Verdean", country_code: "CV" },
  {
    country: "Central African Republic",
    nationality: "Central African",
    country_code: "CF",
  },
  { country: "Chad", nationality: "Chadian", country_code: "TD" },
  { country: "Chile", nationality: "Chilean", country_code: "CL" },
  { country: "China", nationality: "Chinese", country_code: "CN" },
  { country: "Colombia", nationality: "Colombian", country_code: "CO" },
  { country: "Comoros", nationality: "Comorian", country_code: "KM" },
  { country: "Congo", nationality: "Congolese", country_code: "CG" },
  { country: "Congo (DRC)", nationality: "Congolese", country_code: "CD" },
  { country: "Costa Rica", nationality: "Costa Rican", country_code: "CR" },
  { country: "Croatia", nationality: "Croatian", country_code: "HR" },
  { country: "Cuba", nationality: "Cuban", country_code: "CU" },
  { country: "Cyprus", nationality: "Cypriot", country_code: "CY" },
  { country: "Czech Republic", nationality: "Czech", country_code: "CZ" },
  { country: "Denmark", nationality: "Danish", country_code: "DK" },
  { country: "Djibouti", nationality: "Djiboutian", country_code: "DJ" },
  { country: "Dominica", nationality: "Dominican", country_code: "DM" },
  {
    country: "Dominican Republic",
    nationality: "Dominican",
    country_code: "DO",
  },
  { country: "Ecuador", nationality: "Ecuadorian", country_code: "EC" },
  { country: "Egypt", nationality: "Egyptian", country_code: "EG" },
  { country: "El Salvador", nationality: "Salvadoran", country_code: "SV" },
  {
    country: "Equatorial Guinea",
    nationality: "Equatorial Guinean",
    country_code: "GQ",
  },
  { country: "Eritrea", nationality: "Eritrean", country_code: "ER" },
  { country: "Estonia", nationality: "Estonian", country_code: "EE" },
  { country: "Eswatini", nationality: "Swazi", country_code: "SZ" },
  { country: "Ethiopia", nationality: "Ethiopian", country_code: "ET" },
  { country: "Fiji", nationality: "Fijian", country_code: "FJ" },
  { country: "Finland", nationality: "Finnish", country_code: "FI" },
  { country: "France", nationality: "French", country_code: "FR" },
  { country: "Gabon", nationality: "Gabonese", country_code: "GA" },
  { country: "Gambia", nationality: "Gambian", country_code: "GM" },
  { country: "Georgia", nationality: "Georgian", country_code: "GE" },
  { country: "Germany", nationality: "German", country_code: "DE" },
  { country: "Ghana", nationality: "Ghanaian", country_code: "GH" },
  { country: "Greece", nationality: "Greek", country_code: "GR" },
  { country: "Grenada", nationality: "Grenadian", country_code: "GD" },
  { country: "Guatemala", nationality: "Guatemalan", country_code: "GT" },
  { country: "Guinea", nationality: "Guinean", country_code: "GN" },
  {
    country: "Guinea-Bissau",
    nationality: "Bissau-Guinean",
    country_code: "GW",
  },
  { country: "Guyana", nationality: "Guyanese", country_code: "GY" },
  { country: "Haiti", nationality: "Haitian", country_code: "HT" },
  { country: "Honduras", nationality: "Honduran", country_code: "HN" },
  { country: "Hungary", nationality: "Hungarian", country_code: "HU" },
  { country: "Iceland", nationality: "Icelandic", country_code: "IS" },
  { country: "India", nationality: "Indian", country_code: "IN" },
  { country: "Indonesia", nationality: "Indonesian", country_code: "ID" },
  { country: "Iran", nationality: "Iranian", country_code: "IR" },
  { country: "Iraq", nationality: "Iraqi", country_code: "IQ" },
  { country: "Ireland", nationality: "Irish", country_code: "IE" },
  { country: "Israel", nationality: "Israeli", country_code: "IL" },
  { country: "Italy", nationality: "Italian", country_code: "IT" },
  { country: "Jamaica", nationality: "Jamaican", country_code: "JM" },
  { country: "Japan", nationality: "Japanese", country_code: "JP" },
  { country: "Jordan", nationality: "Jordanian", country_code: "JO" },
  { country: "Kazakhstan", nationality: "Kazakhstani", country_code: "KZ" },
  { country: "Kenya", nationality: "Kenyan", country_code: "KE" },
  { country: "Kiribati", nationality: "I-Kiribati", country_code: "KI" },
  { country: "Kuwait", nationality: "Kuwaiti", country_code: "KW" },
  { country: "Kyrgyzstan", nationality: "Kyrgyzstani", country_code: "KG" },
  { country: "Laos", nationality: "Lao", country_code: "LA" },
  { country: "Latvia", nationality: "Latvian", country_code: "LV" },
  { country: "Lebanon", nationality: "Lebanese", country_code: "LB" },
  { country: "Lesotho", nationality: "Basotho", country_code: "LS" },
  { country: "Liberia", nationality: "Liberian", country_code: "LR" },
  { country: "Libya", nationality: "Libyan", country_code: "LY" },
  {
    country: "Liechtenstein",
    nationality: "Liechtensteiner",
    country_code: "LI",
  },
  { country: "Lithuania", nationality: "Lithuanian", country_code: "LT" },
  { country: "Luxembourg", nationality: "Luxembourger", country_code: "LU" },
  { country: "Madagascar", nationality: "Malagasy", country_code: "MG" },
  { country: "Malawi", nationality: "Malawian", country_code: "MW" },
  { country: "Malaysia", nationality: "Malaysian", country_code: "MY" },
  { country: "Maldives", nationality: "Maldivian", country_code: "MV" },
  { country: "Mali", nationality: "Malian", country_code: "ML" },
  { country: "Malta", nationality: "Maltese", country_code: "MT" },
  {
    country: "Marshall Islands",
    nationality: "Marshallese",
    country_code: "MH",
  },
  { country: "Mauritania", nationality: "Mauritanian", country_code: "MR" },
  { country: "Mauritius", nationality: "Mauritian", country_code: "MU" },
  { country: "Mexico", nationality: "Mexican", country_code: "MX" },
  { country: "Micronesia", nationality: "Micronesian", country_code: "FM" },
  { country: "Moldova", nationality: "Moldovan", country_code: "MD" },
  { country: "Monaco", nationality: "Monegasque", country_code: "MC" },
  { country: "Mongolia", nationality: "Mongolian", country_code: "MN" },
  { country: "Montenegro", nationality: "Montenegrin", country_code: "ME" },
  { country: "Morocco", nationality: "Moroccan", country_code: "MA" },
  { country: "Mozambique", nationality: "Mozambican", country_code: "MZ" },
  { country: "Myanmar", nationality: "Burmese", country_code: "MM" },
  { country: "Namibia", nationality: "Namibian", country_code: "NA" },
  { country: "Nauru", nationality: "Nauruan", country_code: "NR" },
  { country: "Nepal", nationality: "Nepali", country_code: "NP" },
  { country: "Netherlands", nationality: "Dutch", country_code: "NL" },
  { country: "New Zealand", nationality: "New Zealander", country_code: "NZ" },
  { country: "Nicaragua", nationality: "Nicaraguan", country_code: "NI" },
  { country: "Niger", nationality: "Nigerien", country_code: "NE" },
  { country: "Nigeria", nationality: "Nigerian", country_code: "NG" },
  { country: "North Macedonia", nationality: "Macedonian", country_code: "MK" },
  { country: "Norway", nationality: "Norwegian", country_code: "NO" },
  { country: "Oman", nationality: "Omani", country_code: "OM" },
  { country: "Pakistan", nationality: "Pakistani", country_code: "PK" },
  { country: "Palau", nationality: "Palauan", country_code: "PW" },
  { country: "Panama", nationality: "Panamanian", country_code: "PA" },
  {
    country: "Papua New Guinea",
    nationality: "Papua New Guinean",
    country_code: "PG",
  },
  { country: "Paraguay", nationality: "Paraguayan", country_code: "PY" },
  { country: "Peru", nationality: "Peruvian", country_code: "PE" },
  { country: "Philippines", nationality: "Filipino", country_code: "PH" },
  { country: "Poland", nationality: "Polish", country_code: "PL" },
  { country: "Portugal", nationality: "Portuguese", country_code: "PT" },
  { country: "Qatar", nationality: "Qatari", country_code: "QA" },
  { country: "Romania", nationality: "Romanian", country_code: "RO" },
  { country: "Russia", nationality: "Russian", country_code: "RU" },
  { country: "Rwanda", nationality: "Rwandan", country_code: "RW" },
  {
    country: "Saint Kitts and Nevis",
    nationality: "Kittitian or Nevisian",
    country_code: "KN",
  },
  { country: "Saint Lucia", nationality: "Saint Lucian", country_code: "LC" },
  {
    country: "Saint Vincent and the Grenadines",
    nationality: "Vincentian",
    country_code: "VC",
  },
  { country: "Samoa", nationality: "Samoan", country_code: "WS" },
  { country: "San Marino", nationality: "Sammarinese", country_code: "SM" },
  {
    country: "Sao Tome and Principe",
    nationality: "Sao Tomean",
    country_code: "ST",
  },
  { country: "Saudi Arabia", nationality: "Saudi", country_code: "SA" },
  { country: "Senegal", nationality: "Senegalese", country_code: "SN" },
  { country: "Serbia", nationality: "Serbian", country_code: "RS" },
  { country: "Seychelles", nationality: "Seychellois", country_code: "SC" },
  {
    country: "Sierra Leone",
    nationality: "Sierra Leonean",
    country_code: "SL",
  },
  { country: "Singapore", nationality: "Singaporean", country_code: "SG" },
  { country: "Slovakia", nationality: "Slovak", country_code: "SK" },
  { country: "Slovenia", nationality: "Slovenian", country_code: "SI" },
  {
    country: "Solomon Islands",
    nationality: "Solomon Islander",
    country_code: "SB",
  },
  { country: "Somalia", nationality: "Somali", country_code: "SO" },
  { country: "South Africa", nationality: "South African", country_code: "ZA" },
  { country: "South Korea", nationality: "South Korean", country_code: "KR" },
  { country: "South Sudan", nationality: "South Sudanese", country_code: "SS" },
  { country: "Spain", nationality: "Spanish", country_code: "ES" },
  { country: "Sri Lanka", nationality: "Sri Lankan", country_code: "LK" },
  { country: "Sudan", nationality: "Sudanese", country_code: "SD" },
  { country: "Suriname", nationality: "Surinamese", country_code: "SR" },
  { country: "Sweden", nationality: "Swedish", country_code: "SE" },
  { country: "Switzerland", nationality: "Swiss", country_code: "CH" },
  { country: "Syria", nationality: "Syrian", country_code: "SY" },
  { country: "Taiwan", nationality: "Taiwanese", country_code: "TW" },
  { country: "Tajikistan", nationality: "Tajikistani", country_code: "TJ" },
  { country: "Tanzania", nationality: "Tanzanian", country_code: "TZ" },
  { country: "Thailand", nationality: "Thai", country_code: "TH" },
  { country: "Timor-Leste", nationality: "Timorese", country_code: "TL" },
  { country: "Togo", nationality: "Togolese", country_code: "TG" },
  { country: "Tonga", nationality: "Tongan", country_code: "TO" },
  {
    country: "Trinidad and Tobago",
    nationality: "Trinidadian or Tobagonian",
    country_code: "TT",
  },
  { country: "Tunisia", nationality: "Tunisian", country_code: "TN" },
  { country: "Turkey", nationality: "Turkish", country_code: "TR" },
  { country: "Turkmenistan", nationality: "Turkmen", country_code: "TM" },
  { country: "Tuvalu", nationality: "Tuvaluan", country_code: "TV" },
  { country: "Uganda", nationality: "Ugandan", country_code: "UG" },
  { country: "Ukraine", nationality: "Ukrainian", country_code: "UA" },
  {
    country: "United Arab Emirates",
    nationality: "Emirati",
    country_code: "AE",
  },
  { country: "United Kingdom", nationality: "British", country_code: "GB" },
  { country: "United States", nationality: "American", country_code: "US" },
  { country: "Uruguay", nationality: "Uruguayan", country_code: "UY" },
  { country: "Uzbekistan", nationality: "Uzbekistani", country_code: "UZ" },
  { country: "Vanuatu", nationality: "Ni-Vanuatu", country_code: "VU" },
  { country: "Vatican City", nationality: "Vatican", country_code: "VA" },
  { country: "Venezuela", nationality: "Venezuelan", country_code: "VE" },
  { country: "Vietnam", nationality: "Vietnamese", country_code: "VN" },
  { country: "Yemen", nationality: "Yemeni", country_code: "YE" },
  { country: "Zambia", nationality: "Zambian", country_code: "ZM" },
  { country: "Zimbabwe", nationality: "Zimbabwean", country_code: "ZW" },
];
